
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { IPromptBase } from '../../../../shared/interfaces/prompt.base.interface';
import { faCodeBranch, faCopy, faGear, faHashtag, faTag } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { PromptService } from '../../../../services/prompt.service';
import { BehaviorSubject, first, Observable } from 'rxjs';


@Component({
  selector: 'app-comp-prompt',
  templateUrl: './prompt.base.component.html',
  styleUrls: ['./prompt.base.component.scss'],
})
export class PromptBaseComponent implements OnInit, OnDestroy {
    $promptBase: BehaviorSubject<IPromptBase | null> = new BehaviorSubject<IPromptBase | null>(null);
    promptBase$: Observable<IPromptBase | null> = this.$promptBase.asObservable();
    $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    loading$: Observable<boolean> = this.$loading.asObservable();
    @Input() promptId!: string;
    @Output() save: EventEmitter<{ _id: string, query: string, change: string; }> =
    new EventEmitter<{ _id: string, query: string, change: string; }>();
    @ViewChild('promptElm') promptElm?: ElementRef;
    faTag = faTag;
    faHashtag = faHashtag;
    faCopy = faCopy;
    faGear = faGear;
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private toastr: ToastrService,
    private promptService: PromptService,
  ) { }

  ngOnInit(): void {
    this.fetchMessage(this.promptId);
  }
  ngOnDestroy(): void {
  }
  public copyToClipboard(text: string): void {
      // this.toastService.newToast({message:'Copied to clipboard', type:'success'});
      this.toastr.success('Copied', 'Copying to clipboard');
       if (navigator.clipboard) {
           navigator.clipboard.writeText(`${text}`);
       } else {
           // Clipboard API not supported, fallback to alternative method
           // Implement your own logic here
       }
   }
   updateBlock(block: Event, prompt: IPromptBase): void {
    const text = (block.target as unknown as HTMLInputElement).innerText;
    this.save.emit({ _id: prompt._id ?? '', query: prompt.query, change: text });
  }
  saveQuery(prompt: IPromptBase): void {
    const text = this.promptElm?.nativeElement.innerText;
    this.save.emit({ _id: prompt._id ?? '', query: prompt.query, change: text });
  }
    saveBlock(prompt: IPromptBase): void {
    const text = this.promptElm?.nativeElement.innerText;
    this.promptService.updateHistory(prompt._id ?? '', prompt.query, text).subscribe(response => {
      this.toastr.success('Saved', 'updated successfully');
      this.fetchMessage(response._id ?? ''); // Fix: Add nullish coalescing operator
    }, error => {
      this.toastr.error('Error', 'unable to save');
    });
  }
  updateMessage(newMessage: IPromptBase): void {
    const currentMessage = this.$promptBase.value;
    if (JSON.stringify(newMessage) !== JSON.stringify(currentMessage)) {
      this.$promptBase.next(null);
      this.$promptBase.next(newMessage);
      this.$loading.next(false);
    }
  }
  fetchMessage(id:string): void {
    this.$loading.next(true);
    this.promptService.getPromptBase(id).pipe(first()).subscribe((data: any) => {
      this.updateMessage(data);
    },
      (error) => {
        console.error('Error fetching latest message:', error);
        this.$loading.next(false);
      }
    )
  }
}
