<div class="app-panel" *ngIf="(promptBase$ | async); let prompt">
    <div class="panel-header">
        <h2>{{prompt.title}}</h2>
        <button class="naked"><fa-icon [icon]="faGear"></fa-icon></button>
    </div>
    <div class="panel-subhead">
        <span class="version">Version {{prompt.query_history?.length|| 1}}</span>
        <span class="token" (click)="copyToClipboard(prompt.token)">
            <p>{{prompt.token}}</p>
            <fa-icon  [icon]="faCopy"></fa-icon>
        </span>
    </div>
    <div class="mb-3"><ng-content></ng-content></div>
    <div class="mb-3">
        <div [ngClass]="prompt.area!=='promptValidation'?'persona-prompt':'security-prompt'" contenteditable="true" #promptElm>
            <p>{{prompt.query}}
            </p>
        </div>
    </div>
    <div class="panel-footer">
        <span><button class="btn" (click)="saveBlock(prompt)">Save Changes</button></span>
    </div>
</div>